import React, { useState, useEffect, useContext } from "react";
import AWS from "aws-sdk";
import { Modal, Box } from "@mui/material";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import { AWSContext } from "../GlobalContext/AWSContext";
// import AxiosInstance from "../Common/Api/ApiHelper";
// import { apiList } from "../Common/Api/ApiList";

// // Style for the modal container
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  minHeight: "50vh",
  overflowY: "auto",
  minWidth: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px",
};

// const SignedUrlModal = ({ path, previewPath }) => {
//   const [signedUrl, setSignedUrl] = useState("");
//   const [open, setOpen] = useState(false);
//   const [loading, setloading] = useState(true);
//   const [accesskey, setAccesskey] = useState("")
//   const [secretkey, setSecretkey] = useState("")
//   const [bucket, setBucket] = useState("")
//   const [reg, setReg] = useState("")
//   const fetchAWSkeys = async () => {
//     try
//     {
//       const res = await AxiosInstance.get(`${apiList.getAwsS3key}?type=1`);
//       if (res.data.s === 1)
//       {
//         console.log(res.data.r)
//         setAccesskey(res?.data?.r?.access_key || "");
//         setSecretkey(res?.data?.r?.secret_key || "");
//         setBucket(res?.data?.r?.bucket_name || "");
//         setReg(res?.data?.r?.region || "")
//       } else
//       {
//         toast.error(res.data.m);
//       }
//       setloading(false);
//     } catch (error)
//     {
//       setloading(false);
//       console.log(error);
//     }
//   };

//   const accessKeyId = accesskey
//   const secretAccessKey = secretkey;
//   const region = reg;
//   const bucketName = bucket;
//   const objectKey = path;

//   useEffect(() => {
//     // Configure AWS SDK
//     AWS.config.update({
//       accessKeyId: accessKeyId,
//       secretAccessKey: secretAccessKey,
//       region: region,
//     });

//     const s3 = new AWS.S3();

//     const params = {
//       Bucket: bucketName,
//       Key: objectKey,
//       Expires: 900,
//     };

//     // Generate pre-signed URL
//     s3.getSignedUrl("getObject", params, (err, url) => {
//       if (err)
//       {
//         setloading(false)
//         console.error("Error generating pre-signed URL", err);
//       } else
//       {
//         setSignedUrl(url);
//         setloading(false)
//       }
//     });
//   }, []);

//   useEffect(() => {
//     fetchAWSkeys()
//   }, [])

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <div>
//       {/* Button to open the modal */}
//       {signedUrl && (
//         <img
//           onClick={handleOpen}
//           src={previewPath}
//           alt=""
//           style={{
//             height: "100px",
//             width: "100px",
//             borderRadius: "50%",
//             border: "1px solid #E0E0E0",
//             objectFit: "cover",
//             cursor: "pointer",
//           }}
//         />
//       )}

//       {/* Modal for displaying the image */}
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box sx={modalStyle}>
//           {!loading ? (
//             <div>
//               <img
//                 src={signedUrl}
//                 alt="Profile"
//                 style={{
//                   maxWidth: "100%",
//                   maxHeight: "80vh",
//                   borderRadius: "12px",
//                 }}
//               />
//             </div>
//           ) : (
//             <div>
//               <Loader />
//             </div>
//           )}
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default SignedUrlModal;

const SignedUrlModal = ({ path, previewPath }) => {
  const [signedUrl, setSignedUrl] = useState("");
  const [open, setOpen] = useState(false);
  const {
    accessKey,
    secretKey,
    bucket,
    region,
    loading: loadingKeys,
  } = useContext(AWSContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (accessKey && secretKey && bucket && region && !loadingKeys) {
      AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey: secretKey,
        region: region,
      });

      const s3 = new AWS.S3();
      const params = {
        Bucket: bucket,
        Key: path,
        Expires: 900,
      };

      // Reset loading state
      setLoading(true);

      s3.getSignedUrl("getObject", params, (err, url) => {
        setLoading(false);
        if (err) {
          console.error("Error generating pre-signed URL", err);
          toast.error("Error generating pre-signed URL");
        } else {
          setSignedUrl(url);
        }
      });
    }
  }, [accessKey, secretKey, bucket, region, path, loadingKeys]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {signedUrl && (
        <img
          onClick={handleOpen}
          src={previewPath}
          alt=""
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            border: "1px solid #E0E0E0",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {!loading && !loadingKeys ? (
            <img
              src={signedUrl}
              alt="Profile"
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                borderRadius: "12px",
              }}
            />
          ) : (
            <Loader />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SignedUrlModal;
