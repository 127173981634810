import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { LoginSchema } from "../../Common/YupSchema";
import ErrorMsgComponent from "../../Component/Error_msg_Component";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../Common/Routes/Path";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { toast } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function LoginFormComponent(props) {
  const { setisLoginScreen } = props;
  const theme = useTheme();
  const nav = useNavigate();
  const [isSubmitted, setisSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    handleReset,
    isSubmitting,
    submitCount,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try
      {

        const f = new FormData()
        f.append('email', values.email)
        f.append('password', values.password)

        const response = await AxiosInstance.post(apiList.LOGIN, f);
        if (response?.data?.s === 1)
        {
          localStorage.setItem("userInfo", JSON.stringify(response?.data?.r));
          nav(PathList.Dashboard);
          toast.success("Login Successfully.");
          handleReset();
        } else
        {
          toast.error(response?.data?.m);
          handleReset();
        }
      } catch (error)
      {
        if (submitCount < 2)
        {
          console.log(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message
          );
          handleReset();
        }
      } finally
      {
        setLoading(false);
      }
    },
  });

  const submitData = (e) => {
    e.preventDefault();
    setisSubmitted(true);
    handleSubmit();
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        padding: "2rem",
        borderRadius: "12px",
        fontFamily: "Helvetica",
        width: { xs: "99%", md: "400px", xl: "500px" },
      }}
    >
      <h2
        style={{ marginTop: "0.5rem", fontWeight: "700", fontSize: "1.5rem" }}
      >
        Welcome Back, Admin
      </h2>
      <Box
        style={{
          marginTop: "0.5rem",
          fontSize: "1.05rem",
        }}
      >
        Enter your e-mail & password to login
      </Box>
      <form onSubmit={submitData}>
        <Box sx={{ mt: "0.5rem" }}>
          <Box>Email</Box>
          <Box sx={{ mt: "0.5rem" }}>
            <TextField
              type="email"
              name="email"
              id="email"
              error={isSubmitted && !!errors.email}
              placeholder="example@email.com"
              onChange={handleChange}
              value={values.email}
              sx={{
                width: "100%",
                ".MuiInputBase-root": {
                  borderRadius: "8px",
                  padding: "10px 0",
                },
              }}
            />
            {isSubmitted && errors.email && (
              <ErrorMsgComponent error={errors.email} />
            )}
          </Box>
        </Box>

        <Box sx={{ mt: "0.5rem" }}>
          <Box>Password</Box>
          <Box sx={{ mt: "0.5rem" }}>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              placeholder="******"
              value={values.password}
              type={showPassword ? "text" : "password"}
              error={isSubmitted && !!errors.password}
              onChange={handleChange}
              sx={{
                width: "100%",
                "&.MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  padding: "10px 0",
                  paddingRight: "1rem",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            // label="Password"
            />
            {isSubmitted && errors.password && (
              <ErrorMsgComponent error={errors.password} />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            mt: "0.5rem",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer", '&:hover': { color: 'grey' } }}
            onClick={() => setisLoginScreen(false)}
          >
            Forgot Password?
          </Box>
        </Box>
        <Box
          sx={{
            mt: "0.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          <button
            style={{
              width: "100%",
              fontFamily: "Helvetica",
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: "700",
              fontSize: "1rem",
              padding: "12px",
              border: "none",
              color: theme.palette.text.button,
              background: theme.palette.primary.Blend,
              cursor: "pointer",
            }}
            disabled={loading || isSubmitting}
            type="submit"
          >
            {loading ? <CircularProgress size={26} /> : "Login"}
          </button>
        </Box>
      </form>
    </Box>
  );
}
